import './bootstrap';
import Alpine from 'alpinejs';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import * as FilePond from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import NodeTextField from './tiptap/NodeTextField';
import SignaturePad from 'signature_pad';
import { createCanvas } from 'vb-canvas';
//import { flare } from "@flareapp/flare-client";
import intersect from '@alpinejs/intersect';
import {Placeholder} from "@tiptap/extension-placeholder";

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
    //flare.light('v6OvpVmTAOi9QTcQGGKR36I1DnslwpWf');
}

FilePond.registerPlugin(FilePondPluginFileValidateType);

window.FilePond = FilePond;

function session() {
    fetch('https://api.ipdata.co?api-key=34bc173f577d7657d27e32482a02580236c4275cf42862e1113de1d6')
        .then(res => res.json())
        .then(data => {
            let formData = new FormData()
            formData.append('ip', data.ip)
            fetch('/hipay/ip', {
                method: 'POST',
                body: formData,
            })
        })
}

session();

window.setupEditor = function (model) {
    return {
        editor: null,
        content: model,
        init(element, isEditable) {
            this.editor = new Editor({
                element: element,
                extensions: [
                    StarterKit.configure({
                        heading: {
                            levels: [1, 2, 3],
                        },
                    }),
                    Underline,
                    NodeTextField,
                    Placeholder.configure({
                        emptyEditorClass: 'is-editor-empty',
                        placeholder: 'Rédigez votre courrier ici…',
                    }),
                ],
                editable: isEditable,
                content: this.content.text,
                onUpdate: ({editor}) => {
                    this.content.text = editor.getHTML();
                    this.content.json = editor.getJSON();
                },
                onSelectionUpdate: ({ editor }) => {},
                onTransaction: ({ editor, transaction }) => {},
                onFocus: ({ editor, event }) => {},
            });

            this.$watch('content', (content) => {
                if (content.text === Alpine.raw(this.editor).getHTML()) return;
                Alpine.raw(this.editor).commands.setContent(content.text, false);
            });
        }
    }
}

document.addEventListener('alpine:init', (e) => {
    Alpine.data('signature', (signature) => ({
        signature: signature,
        canvasWidth: 517,
        canvasHeight: 259,
        signaturePad: null,

        initSignature() {
            const {ctx, el} = createCanvas({
                target: this.$refs.signaturePad,
            });

            const ratio =  Math.max(window.devicePixelRatio || 1, 1);
            el.width = this.$refs.signaturePad.offsetWidth * ratio;
            el.height = this.$refs.signaturePad.offsetHeight * ratio;
            el.getContext("2d").scale(ratio, ratio);

            this.signaturePad = new SignaturePad(el, {
                backgroundColor: 'rgba(255, 255, 255, 0)',
                penColor: 'rgb(0, 0, 0)',
            });

            if(this.signature) {
                this.signaturePad.fromData(this.signature.data);
            }

            this.signaturePad.addEventListener('endStroke', () => {
                this.signature = {
                    'image': this.signaturePad.toDataURL(),
                    'data': this.signaturePad.toData(),
                };
            });

            window.addEventListener('resize', () => {
                const ratio =  Math.max(window.devicePixelRatio || 1, 1);
                el.width = this.$refs.signaturePad.offsetWidth * ratio;
                el.height = this.$refs.signaturePad.offsetHeight * ratio;
                el.getContext("2d").scale(ratio, ratio);
                this.signaturePad.fromData(this.signaturePad.toData());
            });
        }
    }));
    Alpine.data('hipay', (
        hasSubscription,
        showPopup,
        username,
        password,
        environment
    ) => ({
        hasSubscription: !!hasSubscription,
        showError: false,
        show: true,
        showPopup: showPopup,
        showWaiting: false,
        cardInstance: null,
        initHipay() {
            let hipay = HiPay({
                username: username,
                password: password,
                environment: environment,
                lang: 'fr'
            });

            let config = {
                fields: {
                    cardHolder: {
                        selector: 'hipay-card-holder'
                    },
                    cardNumber: {
                        selector: 'hipay-card-number'
                    },
                    expiryDate: {
                        selector: 'hipay-expiry-date'
                    },
                    cvc: {
                        selector: 'hipay-cvc',
                        helpButton: true
                    }
                }
            };

            this.cardInstance = hipay.create('card', config);

            this.cardInstance.on('change', (event) => {
                if(event.error) {
                    this.$refs.error.innerHTML = event.error;
                    this.showError = true;
                } else {
                    this.showError = false;
                    this.$refs.error.innerHTML = '';
                }

                this.$refs.submit.disabled = !event.valid;
            });
        }
    }));
});

Alpine.plugin(intersect);

window.Alpine = Alpine;

Alpine.start();
